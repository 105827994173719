var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"loading":_vm.loading,"outlined":""}},[_c('v-system-bar',[_vm._v("Übersicht")]),(_vm.$vuetify.breakpoint.xs)?_c('v-list',[_c('v-data-iterator',{attrs:{"disable-pagination":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"item-key":"id","item-class":() => 'clickable',"sort-by":"student","disable-sort":"","disable-filtering":""}},_vm._l((_vm.items),function(item){return _c('v-list-item',{key:item.name,attrs:{"to":{
          name: 'AbsencesOverviewStudent',
          params: { id: item.student.id },
        }}},[_c('v-list-item-avatar',[_c('PortraitImage',{attrs:{"value":item.student}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('PersonName',{attrs:{"value":item.student}})],1),_c('v-list-item-subtitle',[_vm._v(" offen "+_vm._s(item.pendingLessons)+" | E "+_vm._s(item.excusedLessons)+" | U "+_vm._s(item.unexcusedLesssons)+" | HT "+_vm._s(item.halfDays)+" ")])],1),_c('v-list-item-action',[(item.warningLevel > 0)?_c('v-badge',{attrs:{"overlap":"","content":item.absentLessons,"color":item.warningLevel == 1 ? 'warning' : 'danger'}},[_c('v-icon',[_vm._v("mdi-alert")])],1):_vm._e()],1)],1)}),1)],1):_c('v-data-table',{attrs:{"disable-pagination":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"item-class":() => 'clickable',"sort-by":"student"},on:{"click:row":(el) =>
        _vm.$router.push({
          name: 'AbsencesOverviewStudent',
          params: { id: el.student.id },
        })},scopedSlots:_vm._u([{key:"item.student",fn:function({ item }){return [_c('PersonItem',{attrs:{"small":"","value":item.student}})]}},{key:"item.absentLessons",fn:function({ item }){return [(item.warningLevel > 0)?_c('v-badge',{attrs:{"overlap":"","content":item.absentLessons,"color":item.warningLevel == 1 ? 'warning' : 'danger'}},[_c('v-icon',[_vm._v("mdi-alert")])],1):_vm._e()]}},{key:"footer",fn:function(){return [_c('TableFooter',{attrs:{"items":_vm.items,"label":"Schüler:innen","labelSingular":"Schüler:in"}})]},proxy:true}])}),_c('router-view',{attrs:{"schoolClass":_vm.schoolClass},on:{"updated":function($event){return _vm.fetchData()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }